import * as React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import {
  GatsbyImage,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image"

import * as styles from "./Header.module.css"

const Header = ({ siteTitle, siteAuthor }) => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: strapiGlobal {
        image: authorMark {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      desktopImage: strapiGlobal {
        image: authorLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      roadmapLogo: strapiGlobal {
        image: siteLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      text: strapiGlobal {
        description: roadmapDescription
      }
    }
  `)

  const images = withArtDirection(getImage(data.desktopImage.image.localFile), [
    {
      media: "(max-width: 600px)",
      image: getImage(data.mobileImage.image.localFile),
    },
  ])

  return (
    <header className={styles.header}>
      <a className={styles.tabulaGamesLogo} href="https://tabula.games/">
        <GatsbyImage
          className={styles.tabulaGamesLogoImage}
          image={images}
          alt={`${siteAuthor} logo`}
        />
      </a>
      <div className={styles.tabulaRoadmapBrand}>
        <GatsbyImage
          image={getImage(data.roadmapLogo.image.localFile)}
          alt={`${siteTitle} logo`}
          objectFit="contain"
        />
        <div className={styles.tabulaRoadmapDescription}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{data.text.description}</ReactMarkdown>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
