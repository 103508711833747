import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/Header"

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteTitle
        siteAuthor
      }
    }
  `)

  return (
    <div className="layout">
      <Header 
        siteTitle={data.strapiGlobal.siteTitle || `Title`} 
        siteAuthor={data.strapiGlobal.siteAuthor || `Author`}
      />
      <main>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
