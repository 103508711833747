import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

function Seo({lang, meta}) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site: strapiGlobal {
          title: siteTitle
          description: siteDescription
          author: siteAuthor
        }
        file: strapiGlobal {
          metaRoadmap {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          favicon {
            localFile {
              publicURL
            }
          }
        }
      }
    `
  )

  const metaDescription = site.description
  const title = site.title
  const author = site.author
  const favicon = file.favicon.localFile

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={author ? `%s | ${author}` : null}
      link={[
        {
          rel: "icon",
          href: favicon.publicURL,
        }
      ]}

      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: getSrc(file.metaRoadmap.localFile.childImageSharp.gatsbyImageData),
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: getSrc(file.metaRoadmap.localFile.childImageSharp.gatsbyImageData),
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
